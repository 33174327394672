@use 'mixins';

.Modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0 30%;
    height: 100%;
    z-index: 500;
    overflow: auto;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;

    &::before {
        content: '';
        display: none;
        background-color: black;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        pointer-events: none;
        opacity: 0.4;
    }

    &:global(.shown) {
        display: block;

        &::before {
            display: block;
        }
    }

    header {
        padding: var(--largePadding) 0;
        border-bottom: 1px solid rgb(var(--sectionBorder));
        margin-bottom: var(--largePadding);

        h1 {
            display: inline-block;
        }

        :global(.Icon) {
            float: right;
        }
    }

    section {
        background: white;
        margin-top: var(--hugePadding);
        margin-bottom: var(--standardPadding);
        padding: 0 var(--largePadding) var(--largePadding);
    }

    footer {
        text-align: right;
        padding-top: var(--standardPadding);

        button {
            width: 100%;
            margin: 0;
        }
    }
}

@include mixins.responsive(m, below) {
    .Modal {
        padding: 0;
        background: rgb(var(--background));
        overflow: hidden;
        pointer-events: none;

        section {
            position: absolute;
            height: 100%;
            inset: 0;
            padding: 0 var(--largePadding) 7em;
            margin: 0;
            pointer-events: all;
            box-sizing: border-box;
            overflow-y: auto;
        }

        footer {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgb(var(--lightSectionBack));
            border-top: 1px solid rgb(var(--sectionBorder));
            padding: var(--largePadding);
        }
    }
}

.ArticleBlock {
    display: grid;
    grid-template-columns: 15% 70% 15%;
    padding: var(--smallPadding);
    background: rgb(var(--background));
    margin-bottom: var(--largePadding);

    .content {
        color: var(--titleColor);
        text-align: center;
        align-self: center;
        > p {
            margin: 0 !important;
            line-height: var(--text-line-height);
            font-weight: var(--font-weight-semi-bold);
        }

        > h6 {
            margin: var(--smallPadding) 0;
            font-weight: var(--font-weight-regular);
            font-size: var(--tag-large-font-size);
        }
    }

    .buttonGroupContainer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .buttonGroup {
        padding: var(--smallPadding);
        background: rgb(var(--lightSectionBack));
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        height: fit-content;
        width: fit-content;
    }

    .button {
        border: none;
        background: rgb(var(--lightSectionBack));
        color: rgb(var(--titleColor));

        &:hover {
            background: rgb(var(--sectionBorder));
            color: rgb(var(--titleColor));
        }
    }
}
